
.layout {
  display: grid;
  grid-template-areas: 'nav head''nav main';
  grid-template-columns: auto 10fr;
  grid-template-rows: 64px 10fr;
  background-color: var(--neutral-bg-weak);
  height: 100vh;

  .layout__header {
    grid-area: head;
    background-color: #F0F4F8;
  }

  .layout__navbar {
    grid-area: nav;
    box-shadow: 0 var(--spacing-1) var(--spacing-3) 0 #00000026;
    user-select: none;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .layout__content {
    grid-area: main;
    background-color: #F0F4F8;
    height: 100%;
    position: relative;
    overflow-y: auto;

    .app__content {
      padding: var(--spacing-7);
    }
  }

  .layout__footer {
    grid-area: footer;
    box-shadow: inset 0 -1px 0 0 #d0d5d9;
  }
}

@media (max-width: 1023px) {
  .layout {
    grid-template-areas: 'nav head''main main''main main';
    grid-template-columns: 96px 10fr;

    .layout__navbar {
      box-shadow: 0 0 0 0;
      border-right: 1px solid var(--neutral-border-weak);
      margin-right: var(--spacing-3);
    }
  }
}